import React from "react";
import { graphql } from "gatsby";

import { Container, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Layout from "./layout";
import Seo from "./seo";

import { Mdx } from "@runly/gatsby-theme";

const LegalPage = ({
	location,
	data: {
		file: {
			childMdx: {
				frontmatter: { title, lastRevised },
				body,
				tableOfContents: { items: toc }
			}
		}
	}
}) => {
	const classes = useStyles();

	return (
		<Layout location={location} maxWidth="md">
			<Seo title={title} />

			<Container maxWidth="md">
				<header className={classes.header}>
					<Typography variant="h2">{title}</Typography>
					<Typography variant="subtitle1" className={classes.revision}>
						Last revised on {lastRevised}
					</Typography>
				</header>

				{toc ? (
					<Typography component="div">
						<nav className={classes.toc}>
							<ol>
								{toc.map(({ url, title }) => (
									<li key={url}>
										<Link href={url} color="inherit">
											{title}
										</Link>
									</li>
								))}
							</ol>
						</nav>
					</Typography>
				) : null}

				<article>
					<Mdx>{body}</Mdx>
				</article>
			</Container>
		</Layout>
	);
};

const useStyles = makeStyles(theme => ({
	header: {
		marginBottom: theme.spacing(3)
	},
	toc: {
		margin: theme.spacing(3, 0),

		"& ol": {
			margin: theme.spacing(0, 3),
			padding: 0,

			"& li": {
				margin: theme.spacing(0.5, 0)
			}
		}
	},
	revision: {
		fontStyle: "italic"
	}
}));

export default LegalPage;

export const legalPageQuery = graphql`
	query LegalBySlug($slug: String!) {
		file(
			sourceInstanceName: { eq: "legal" }
			childMdx: { fields: { slug: { eq: $slug } } }
		) {
			childMdx {
				frontmatter {
					title
					lastRevised(formatString: "LL")
				}
				body
				tableOfContents(maxDepth: 2)
			}
		}
	}
`;
